.wrapper-data {
    overflow-y: auto;
}

.table-titles {
    background-color: #30354A;
}

/* .wrapper-data tbody td{
   font-size: 10px !important;
   padding: 0px;
} */

.wrapper-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.wrapper-buttons {
    display: flex;
    margin-bottom: 10px;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
}

.wrapper-table .action-button {
    padding: 0px !important;
}

.css-1h37f49-MuiTableCell-root {
    font-weight: bold !important;
}

.img-cell {
    height: 30px;
}

@media (max-width: 1400px) {
    .wrapper-data {
        /* height: 56vh; */
    }
}

.wrapper-content-actions {
    box-shadow: 5px 5px 5px #bdbdbc;

}

.btn-action {
    /* padding: 12px 20px; */
    /* border-bottom: 1px solid #7b7979; */
    /* display: flex; */
    /* align-items: center; */
    border: none !important;
    min-width: 10px !important;
    padding: 0px 3px !important;
    /* padding-right: 10px !important; */
    /* padding: 5px !important; */
    /* padding: 0px !important; */
    /* cursor: pointer; */
}

.btn-action svg {
    font-size: 20px;
    height: 20px !important;
    fill: #30354A;
    stroke: #ffffff; 
    stroke-width: 1px
    /* margin-right: 10px; */
}
.MuiTableRow-root:nth-of-type(odd) .btn-action svg{
    stroke: #e0e0e0; 
}